import { Brand, DeliveryAddress } from '..';

export function Left() {
	return (
		<>
			<Brand />
			<DeliveryAddress />
		</>
	);
}

export default Left;
